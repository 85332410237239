import { useState, Children } from "react"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet"

import { Grid, Tabs, Tab, Box } from "@mui/material"

import { Heading } from "@/common/StyledComponents"


export default function BaseAdmin({ title, subtitle, tabs, extraItems, children }){
    const { tab } = useParams()
    const tabUrls = tabs.map(t => t.replace(" ", "-").toLowerCase())
    const urlTab = tabUrls.indexOf(tab)

    const [tabIndex, setTabIndex] = useState(urlTab == -1 ? 0 : urlTab)

    const handleTabChange = (event, newTab) => {
        setTabIndex(newTab)
        const path = window.location.pathname.split('/')[1]
        window.history.replaceState({}, "", `/${path}/${tabUrls[newTab]}`);
    }

    return (
        <>
            <Helmet><title>{ title } - Makers Central</title></Helmet>
            <Heading>{ title }</Heading>
            { subtitle && (
                <span style={{color: "gray"}}>{ subtitle }</span>
            )}

            <Grid container justifyContent="space-between" sx={{marginTop: "24px"}}>
                <Grid item>
                    <Tabs value={tabIndex} onChange={handleTabChange}>
                        {tabs.map((t, i) => (
                            <Tab key={i} label={t} />
                        ))}
                    </Tabs>
                </Grid>
                <Grid item>
                    { extraItems }
                </Grid>
            </Grid>

            { Children.map(children, (panel, i) => (
                <TabPanel key={i} value={tabIndex} index={i}>
                    { panel }
                </TabPanel>    
            )) }
        </>
    )
}

const TabPanel = ({ children, value, index, ...props }) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        {...props}
    >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
)
