import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import { request } from "@/Api";
import { SocketDiv } from "@/common/StyledComponents";
import { selectBidUpdate, selectTopSheetHighlights, updateWorkingLine, clearWorkingLine, updateBid, updateDetails } from "@/bid/bidSlice";

const NoteGridItem = (props) => {
    const { placeholder, field, onInputChange, onInputBlur, onInputFocus, localBid, readOnly, highlights } = props    
    return (
        <Grid item md={12}>
            <SocketDiv highlights={highlights.filter(highlight=>highlight.field==field)}>
                <TextField
                    value={localBid[field] || ""}
                    placeholder={placeholder}
                    fullWidth
                    multiline
                    minRows={4}
                    onBlur={onInputBlur(field)}
                    onInput={onInputChange(field)}
                    onFocus={onInputFocus(field)}
                    disabled={readOnly}
                />
            </SocketDiv>
        </Grid>
    )
}

export default function BidNotes(props){
    const { bidId, readOnly, sendSocketMessage } = props
    const [localBid, setLocalBid] = useState({})
    const dispatch = useDispatch()
    const bid = useSelector(selectBidUpdate)

    const highlights = useSelector(selectTopSheetHighlights)

    const updateBid = (bidPayload) => {
        dispatch(updateDetails(bidPayload))
        request.patch(`/bids/${bidId}`, bidPayload)
        .then(()=>{
            sendSocketMessage({type: "update", bidId: bidId})
        })
    }

    const onInputChange = (field) => (event) => {
        dispatch(updateWorkingLine({field: field, value: event.target.value}))
        setLocalBid({...localBid, [field]: event.target.value})
    }

    const onInputBlur = (field) => (event) => {
        dispatch(clearWorkingLine())

        var value = event.target.value.trim()
        setLocalBid({...localBid, [field]: value})

        if (bid[field] != value) {
            updateBid({...localBid, [field]: value})
        }
    }

    const onInputFocus = (field) => (event) => {
        event.preventDefault()
        sendSocketMessage({type: "highlight", data: {lineId: -1, field}})
    }

    useEffect(()=>{
        if (!bid.id) {
            return
        }

        setLocalBid({...bid, top_sheet_sender_ids: bid.top_sheet_senders.map((sender)=>{return sender.id})})
    },[bid])

    return (
        <>
            <Grid item><p>Notes</p></Grid>

            <Grid container spacing={4}>
                <NoteGridItem
                    placeholder="Project Assumptions"
                    field="assumptions"
                    onInputChange={onInputChange}
                    onInputBlur={onInputBlur}
                    onInputFocus={onInputFocus}
                    localBid={localBid}
                    readOnly={readOnly}
                    highlights={highlights}
                />
                <NoteGridItem
                    placeholder="Bid Includes..."
                    field="inclusions"
                    onInputChange={onInputChange}
                    onInputBlur={onInputBlur}
                    onInputFocus={onInputFocus}
                    localBid={localBid}
                    readOnly={readOnly}
                    highlights={highlights}
                />
                <NoteGridItem
                    placeholder="Bid does not include..."
                    field="exclusions"
                    onInputChange={onInputChange}
                    onInputBlur={onInputBlur}
                    onInputFocus={onInputFocus}
                    localBid={localBid}
                    readOnly={readOnly}
                    highlights={highlights}
                />
            </Grid>
        </>
    )
}
